import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import MediaLibrary from '../components/MediaLibrary'
import { BottomLineGoogleAdContainer } from '../components/styles/GoogleAdsCommon.styles'
import { paginationChange } from '../util/helper'
import AdHead from '../components/AdHead'
import AdSlot from '../components/AdSlot'

export const Head = ({ pageContext }) => (
  <AdHead adsData={pageContext.adsData} targets={pageContext.pageTargets} />
)

const MediaLibraryLandingPage = ({ data }) => {
  const doc = data.prismicMediaLibraryPage.data
  const types = data.allPrismicMediaLibraryItemType.edges
  const topics = data.allPrismicMediaLibraryItemTopic.edges
  const mediaItemsList = data.allPrismicMediaLibraryItem.edges

  if (!doc || !types || !topics || !mediaItemsList) {
    return null
  }

  const twitterImage = doc.twitter_image?.url
    ? doc.twitter_image
    : doc.highlight_media_item.hero_image?.url
    ? doc.highlight_media_item.hero_image
    : null
  const facebookImage = doc.facebook_image?.url
    ? doc.facebook_image
    : doc.highlight_media_item.hero_image?.url
    ? doc.highlight_media_item.hero_image
    : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : 'Watch and Listen'
  const seo_embed_code = doc.seo_embed_code?.text ?? null
  const bottomLineAdTag =
    data.prismicGeneralSiteSettings?.data?.media_library_landing_page_google_ad
      ?.document?.data?.ad_slot_name
  const sponsorshipText = doc.sidebar_sponsorship_text
    ? doc.sidebar_sponsorship_text
    : null

  return (
    <>
      <SEO
        seo={{
          description: doc.meta_description,
          keywords: doc.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout>
        <MediaLibrary
          doc={doc}
          types={types}
          topics={topics}
          mediaItemsList={mediaItemsList}
          paginationChangeAction={paginationChange}
          sponsorshipText={sponsorshipText}
        />
        {bottomLineAdTag && (
          <BottomLineGoogleAdContainer>
            <AdSlot divId={`div-${bottomLineAdTag}`} />
          </BottomLineGoogleAdContainer>
        )}
      </Layout>
    </>
  )
}

MediaLibraryLandingPage.propTypes = {
  data: object,
}

export const query = graphql`
  query MediaLibraryLandingPageQuery {
    prismicGeneralSiteSettings {
      data {
        excluded_documents {
          excluded_document {
            uid
          }
        }
        media_library_landing_page_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
        media_library_item_interstitial_ad_tag {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
      }
    }
    allPrismicMediaLibraryItemType(sort: { fields: data___type, order: ASC }) {
      edges {
        node {
          id
          data {
            type
          }
        }
      }
    }
    allPrismicMediaLibraryItemTopic(
      sort: { fields: data___topic, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            topic
          }
        }
      }
    }
    prismicMediaLibraryPage {
      data {
        body_text {
          richText
        }
        title {
          text
          richText
        }
        sidebar_sponsorship_text {
          text
          richText
        }
        facebook_image {
          url
        }
        meta_description
        meta_keywords
        meta_title {
          text
        }
        seo_embed_code {
          text
        }
        twitter_image {
          url
        }
        cta_link_text
        display_name
        cta_link {
          url
          uid
          type
          id
          link_type
        }
        highlight_media_item {
          id
          link_type
          uid
          document {
            ... on PrismicMediaLibraryItem {
              id
              uid
              type
              data {
                title {
                  richText
                  text
                }
                body_text {
                  richText
                  text
                }
                hero_image {
                  alt
                  url
                  thumbnails {
                    mobile {
                      url
                    }
                    library_thumbnail {
                      url
                    }
                    library_hero_mobile {
                      url
                    }
                    library_hero_desktop {
                      url
                    }
                  }
                }
                show_cme_tag
                media_type {
                  document {
                    ... on PrismicMediaLibraryItemType {
                      id
                      data {
                        type
                      }
                    }
                  }
                }
                topic {
                  document {
                    ... on PrismicMediaLibraryItemTopic {
                      id
                      data {
                        topic
                      }
                    }
                  }
                }
                body {
                  ... on PrismicMediaLibraryItemDataBodyPodcast {
                    id
                    slice_type
                    primary {
                      podcast_episode_date
                      bottom_padding_adjustment
                    }
                  }
                  ... on PrismicMediaLibraryItemDataBodyUpcomingWebinar {
                    id
                    slice_type
                    primary {
                      event_time
                      bottom_padding_adjustment
                    }
                  }
                  ... on PrismicMediaLibraryItemDataBodyVideoBlock {
                    id
                    slice_type
                    primary {
                      caption {
                        text
                      }
                      schema_publish_date
                      schema_video_description
                      video_duration_hours
                      video_duration_minutes
                      video_duration_seconds
                      video_link {
                        id
                        type
                      }
                      bitmovin_stream_id
                      transcription_link {
                        url
                        uid
                        type
                        link_type
                        id
                      }
                      bottom_padding_adjustment
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicMediaLibraryItem(
      sort: { fields: data___datestamp, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          data {
            datestamp
            show_cme_tag
            title {
              richText
              text
            }
            body_text {
              text
              richText
            }
            hero_image {
              url
              alt
              thumbnails {
                library_hero_desktop {
                  url
                }
                library_hero_mobile {
                  url
                }
                library_thumbnail {
                  url
                }
              }
            }
            topic {
              id
              uid
              type
              link_type
              document {
                ... on PrismicMediaLibraryItemTopic {
                  data {
                    topic
                  }
                }
              }
            }
            media_type {
              id
              uid
              type
              link_type
              document {
                ... on PrismicMediaLibraryItemType {
                  data {
                    type
                  }
                }
              }
            }
            body {
              ... on PrismicMediaLibraryItemDataBodyPodcast {
                id
                slice_type
                primary {
                  podcast_episode_date
                  bottom_padding_adjustment
                }
              }
              ... on PrismicMediaLibraryItemDataBodyUpcomingWebinar {
                id
                slice_type
                primary {
                  event_time
                  bottom_padding_adjustment
                }
              }
              ... on PrismicMediaLibraryItemDataBodyVideoBlock {
                id
                slice_type
                primary {
                  caption {
                    text
                  }
                  schema_publish_date
                  schema_video_description
                  video_duration_hours
                  video_duration_minutes
                  video_duration_seconds
                  video_link {
                    id
                    type
                  }
                  bitmovin_stream_id
                  transcription_link {
                    url
                    uid
                    type
                    link_type
                    id
                  }
                  bottom_padding_adjustment
                }
              }
            }
          }
        }
      }
    }
  }
`

export default MediaLibraryLandingPage
